import React from 'react';
import './Header.scss';

const Header = () => {
  return (
    <header>
      <div className="logo-header">
        <div className="logo"></div>
      </div>
      <div className="report-title">
        <h1>Maandelijks Verbruiksrapport</h1>
        <p>Bedrijfsnaam | Locatie | Gebouw | Periode: Augustus 2024</p>
      </div>
    </header>
  );
};

export default Header;

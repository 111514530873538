import React from 'react';
import './Overview.scss';
import { ChartData } from '../models/ChartData';

interface DataItem {
  label: string;
  value: string;
}
interface UsageProps {
  eData: ChartData | null;
  gData: ChartData | null;
}

const Overview: React.FC<UsageProps> = ({eData, gData}) => {
  const totalElectricity = eData?.series[0].data.reduce((prev, curr) => prev + curr[1], 0) || 0;
  const totalGas = gData?.series[0].data.reduce((prev, curr) => prev + curr[1], 0) || 0;
  const totalCo2 = totalElectricity * 0.3 + totalGas * 2.5;
  const kWhMax = eData?.series[0].data.reduce((prev, curr) => Math.max(prev, curr[1]), 0) || 0;
  const data: DataItem[] = [
    { label: 'Elektriciteit', value: `${totalElectricity.toLocaleString('nl-NL', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } kWh` },
    { label: 'Gas', value: `${totalGas.toLocaleString('nl-NL', {minimumFractionDigits: 1, maximumFractionDigits: 1})} m³` },
    { label: 'Kosten', value: '€ -' },
    { label: 'CO2-uitstoot', value: `${totalCo2.toLocaleString('nl-NL', {minimumFractionDigits: 1, maximumFractionDigits: 1})} kg` },
    { label: 'WEii Score', value: '-' },
    { label: 'kWh Max', value: `${kWhMax.toLocaleString('nl-NL', {minimumFractionDigits: 1, maximumFractionDigits: 1})} kWh` },
  ];

  return (
    <section className="overview-section">
      <div className="overview">
        <p className="explanation">
          Dit gedeelte biedt een snel overzicht van de belangrijkste statistieken voor de maand augustus 2024, waaronder
          elektriciteitsverbruik, gasverbruik, kosten, CO2-uitstoot, WEii-score en gecontracteerd vermogen.
        </p>
        {data.map((item, index) => (
          <div className="overview-box" key={index}>
            {item.label}: <strong>{item.value}</strong>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Overview;

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ChartData } from '../models/ChartData';
import './ChartSection.scss';

interface ChartSectionProps {
  title: string;
  explanation: string;
  data: ChartData | null;
}
const ChartSection = ({ title, explanation, data }: ChartSectionProps) => {
  const options: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%e %b',
        week: '%e %b',
        month: '%b \'%y',
        year: '%Y',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    tooltip: {
      xDateFormat: '%A, %b %e, %Y %H:%M',
      shared: true,
    },
    plotOptions: {
      series: {
        animation: false
      }
    },
    colors: ['#23a301', '#ffda00'],
    series: data?.series.map((seriesItem) => ({
      name: seriesItem.name,
      data: seriesItem.data,
      type: 'line',
    })),
  };

  return (
    <section className="content page-break chart-section">
      <h2>{title}</h2>
      <p className="explanation">{explanation}</p>
      <div className="chart">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </section>
  );
};

export default ChartSection;

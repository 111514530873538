import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import './styles/_print.scss';
import Header from './components/Header';
import YearReportPage from './pages/YearReport';
import MonthlyReport from './pages/Monthly/MonthlyReport';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<YearReportPage />} />
          <Route path="/monthly" element={<MonthlyReport />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import Overview from '../components/Overview';
import ChartSection from '../components/ChartSection';
import axios from 'axios';
import { transformMeasDataToChartData } from '../utils/dataTransform';
import { MeasResponseData } from '../models/MeasResponseData';
import { ChartData } from '../models/ChartData';

const YearReportPage = () => {
  const [electricityData, setElectricityData] = useState<ChartData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const ean = '871685900003698420';
  const fromDate = '2024-09-13';
  const toDate = '2024-09-13';
  const resolution = 'Original';

  useEffect(() => {
    const fetchElectricityData = async () => {
      try {
        const response = await axios.get<MeasResponseData>(
          `${process.env.REACT_APP_API_URL}/micromeas`,
          {
            params: {
              ean,
              from: fromDate,
              to: toDate,
              resolution,
            },
          }
        );

        const transformedData = transformMeasDataToChartData(response.data);
        setElectricityData(transformedData);
      } catch (error) {
        console.error('Error fetching electricity data:', error);
        setError('Failed to load data.');
      } finally {
        setLoading(false);
      }
    };

    fetchElectricityData();
  }, [ean, fromDate, toDate, resolution]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !electricityData) {
    return <div>{error || 'No data available.'}</div>;
  }

  return (
    <>
      {/*<Overview />*/}
      <ChartSection
        title="SANYA"
        explanation="Hieronder staat het energieverbruik gebaseerd op meetgegevens."
        data={electricityData}
      />
    </>
  );
};

export default YearReportPage;

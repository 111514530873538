import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ChartSection from '../../components/ChartSection';
import { transformMeasDataToChartData } from '../../utils/dataTransform';
import { ChartData } from '../../models/ChartData';
import { MeasResponseData } from '../../models/MeasResponseData';
import querystring from 'query-string';
import Overview from '../../components/Overview';
import ElectricityUsageChart from './components/ElectricityUsageChart';
import moment from 'moment';

const MonthlyReport: React.FC = () => {
  const location = useLocation();
  const { eEan, gEan, from, to, resolution } = querystring.parse(location.search);

  const [eData, setEData] = useState<ChartData | null>(null);
  const [monthlyEData, setMonthlyEData] = useState<ChartData | null>(null);
  const [gData, setGData] = useState<ChartData | null>(null);
  const [monthlyGData, setMonthlyGData] = useState<ChartData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (eEan) {
          const eResponse = await axios.get<MeasResponseData>(`${process.env.REACT_APP_API_URL}/micromeas`, {
            params: {
              ean: eEan as string,
              from: from || moment().startOf('month').format('YYYY-MM-DD'),
              to: to || moment().endOf('month').format('YYYY-MM-DD'),
              resolution: 'PerDay',
            },
          });
          setEData(transformMeasDataToChartData(eResponse.data, 'Levering', 'Teruglevering'));

          const monthlyEResponse = await axios.get<MeasResponseData>(`${process.env.REACT_APP_API_URL}/micromeas`, {
            params: {
              ean: eEan as string,
              from: moment().startOf('year').format('YYYY-MM-DD'),
              to: moment().endOf('year').format('YYYY-MM-DD'),
              resolution: 'PerMonth',
            },
          });
          setMonthlyEData(transformMeasDataToChartData(monthlyEResponse.data, 'Levering', 'Teruglevering'));
        }

        if (gEan) {
          const gResponse = await axios.get<MeasResponseData>(`${process.env.REACT_APP_API_URL}/micromeas`, {
            params: {
              ean: gEan as string,
              from: from || moment().startOf('month').format('YYYY-MM-DD'),
              to: to || moment().endOf('month').format('YYYY-MM-DD'),
              resolution: 'PerDay',
            },
          });
          setGData(transformMeasDataToChartData(gResponse.data, 'Gasverbruik'));

          const monthlyGResponse = await axios.get<MeasResponseData>(`${process.env.REACT_APP_API_URL}/micromeas`, {
            params: {
              ean: gEan as string,
              from: moment().startOf('year').format('YYYY-MM-DD'),
              to: moment().endOf('year').format('YYYY-MM-DD'),
              resolution: 'PerMonth',
            },
          });
          setMonthlyGData(transformMeasDataToChartData(monthlyGResponse.data, 'Gasverbruik'));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [eEan, gEan, from, to, resolution]);

  if (!eEan && !gEan) {
    return <div>Missing parameters.</div>;
  }

  if (!eData && !gData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Overview
        eData={eData}
        gData={gData}
      />

      <ChartSection
        title="Elektriciteitsverbruik"
        explanation="Hieronder staat het maandelijkse elektriciteitsverbruik van het huidige jaar vergeleken met het vorige jaar. Deze vergelijking helpt om variaties in het elektriciteitsverbruik gedurende de maanden te begrijpen."
        data={eData}
      />

      {/*<ChartSection*/}
      {/*  title="Energieproductie"*/}
      {/*  explanation="Deze grafiek toont de maandelijkse energieproductie voor het huidige jaar, vergeleken met dezelfde periode van vorig jaar. De gegevens omvatten energieopwekking en teruglevering aan het net, wat inzicht geeft in de jaarlijkse energieproductie trends."*/}
      {/*  data={eData}*/}
      {/*/>*/}

      <ChartSection
        title="Gasverbruik"
        explanation="Hieronder staat het maandelijkse gasverbruik van het huidige jaar vergeleken met het vorige jaar. Deze vergelijking helpt om variaties in het gasverbruik gedurende de maanden te begrijpen."
        data={gData}
      />



      <ChartSection
        title="Jaaroverzicht  Gasverbruik"
        explanation="Hieronder staat een jaarlijkse vergelijking van het elektriciteits- en gasverbruik van het huidige jaar vergeleken met het voorgaande jaar. Dit overzicht biedt inzicht in de totale energieverbruiksgegevens."
        data={monthlyGData}
      />

      <ChartSection
        title="Jaaroverzicht Elektriciteitsverbruik"
        explanation="Dit gedeelte biedt een overzicht van het daadwerkelijke energieverbruik van het vorige jaar, samen met de gemiddelde WEii-score. De onderstaande grafiek toont de CO2-uitstoot, wat helpt om de milieu-impact te begrijpen."
        data={monthlyEData}
      />
      {/*<ChartSection*/}
      {/*  title="Jaaroverzicht"*/}
      {/*  explanation="Dit gedeelte biedt een overzicht van het daadwerkelijke energieverbruik van het vorige jaar, samen met de gemiddelde WEii-score. De onderstaande grafiek toont de CO2-uitstoot, wat helpt om de milieu-impact te begrijpen."*/}
      {/*  data={monthlyEData}*/}
      {/*/>*/}
    </div>
  );
};

export default MonthlyReport;

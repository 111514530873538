import moment from 'moment';
import { MeasResponseData } from '../models/MeasResponseData';
import { ChartData } from '../models/ChartData';

export interface SeriesData {
  name: string;
  data: number[][];
}
export const transformMeasDataToChartData = (
  data: MeasResponseData,
  importLabel = 'Import',
  exportLabel = 'Export',): ChartData => {
  const importDataPoints = data.list.import;
  const exportDataPoints = data.list.export;

  const importSeriesData = importDataPoints.map((point) => {
    const timestamp = moment(point.intervalFrom).valueOf();
    return [timestamp, point.value];
  });

  const exportSeriesData = exportDataPoints.map((point) => {
    const timestamp = moment(point.intervalFrom).valueOf();
    return [timestamp, point.value];
  });

  const chartData: ChartData = {
    series: []
  };

  if(importSeriesData.length > 0) {
    chartData.series.push(
      {
        name: importLabel,
        data: importSeriesData,
      });
  }


  if(exportSeriesData.some((point) => point[1] !== null)) {
    chartData.series.push({
      name: exportLabel,
      data: exportSeriesData,
    });
  };

  return chartData;
};
